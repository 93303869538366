a.activeNav {
  text-decoration: none;
  color: #1cffbb;
}
a.inactiveNav {
  text-decoration: none;
  color: white;
  vertical-align: middle;
  align-items: middle;
  &:hover {
    color: #00e29f;
  }
}

.inactiveNav {
  &:hover {
    background-color: red !important;
  }
}

a {
  color: #fff;
}

.hoverPercent {
  border: 0.5px solid;
  border-color: #78909c;
  color: #78909c !important;
  border-radius: 16px;
  cursor: pointer;
}
.hoverPercent:hover {
  border-color: #1cffbb;
  color: #1cffbb !important;
  background: #1b1b1b;
}

.wallet-adapter-button-trigger {
  backdrop-filter: blur(10px);
  background: #0d101370;
  &:hover {
    background: #0d101340 !important;
  }
  font-size: 12px;
  padding: 14px;
  border-radius: 8px;
}

body {
  background: #0d1013;
  background-image: linear-gradient(165deg, #555f6930 3.89%, #555f6900 66.42%);
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-grow: 1;
  z-index: -1;
}

.background div {
  position: absolute;
  border-radius: 100%;
  height: 0;
  filter: blur(10vw);
  opacity: 1;
}

.background div:nth-child(1) {
  background: linear-gradient(132deg, #19706a 0%, #1f91cf 100%);
  width: 10%;
  padding-top: 10%;
  left: 20%;
  top: 20%;
  transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(2) {
  background: linear-gradient(132deg, #0800ff 0%, #e32be3 100%);
  width: 10%;
  padding-top: 10%;
  left: 40%;
  top: 60%;
  transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(3) {
  background: linear-gradient(132deg, #1fcfc3 0%, #1f91cf 100%);
  width: 10%;
  padding-top: 10%;
  left: 80%;
  top: 60%;
  transform: translateX(-50%) translateY(-50%);
}

.background div:nth-child(4) {
  background: linear-gradient(132deg, #b756e5 0%, #7fb323 100%);
  width: 10%;
  padding-top: 10%;
  left: 68%;
  top: 1%;
  transform: translateX(-50%) translateY(-50%);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000020;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}
